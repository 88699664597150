<template>
  <div>
    <v-row class="px-4" justify="end">
      <v-col class="align" cols="12">
        <label class="font-weight-bold mr-2">{{ $t(`label.searchDate`) }}</label>
        <div class="d-inline-block mx-1 transaction-page-search-date-field-box">
          <v-menu ref="menu" v-model="searchDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                color="secondary"
                :placeholder="$t(`label.from`)"
                append-icon="event"
                outlined
                class="input-field"
                hide-details
                dense
                readonly
                v-on="on"
                v-model="displaySearchDate"
              ></v-text-field>
            </template>
            <v-date-picker
              range
              v-model="searchDate"
              scrollable
              color="secondary"
              :min="minSelectableDate"
              :max="maxSelectableDate"
              :locale="language"
              :selected-items-text="`${searchDate[0]} - ${searchDate[1]}`"
              @change="reorderDateRangeValue"
            ></v-date-picker>
          </v-menu>
        </div>
        <v-btn tile class="mx-1" width="40" height="40" color="secondary" @click="searchAgentDetailReport()">
          <v-icon>search</v-icon>
        </v-btn>
      </v-col>
      <v-col class="align body-2" cols="12" v-if="expandedData.length <= 0">
        <label>{{ `${$t(`label.totalDownlineAgent`)} = ` }}</label>
        <label class="font-weight-bold">{{ affiliateAgentDetailPagination.totalItems }}</label>
      </v-col>
      <v-col class="align body-2" cols="12" v-else>
        <label>{{ `${$t(`label.viewAgentGameReport`)}: ` }}</label>
        <label class="font-weight-bold">{{ expandedData[0].fullMemberCode }}</label>
        <v-btn small class="ml-2 buttonPrimary white--text py-1 px-3" @click="expandedData = []">{{ $t(`label.back`) }}</v-btn>
      </v-col>
    </v-row>

    <v-data-table
      v-if="expandedData.length <= 0"
      mobile-breakpoint="800"
      class="transaction-table px-4"
      :headers="tableHeaders"
      :items="affiliateAgentDetail.list"
      hide-default-footer
      single-expand
      :no-data-text="$t(`message.noDataAvaliable`)"
      item-key="index"
      :expanded.sync="expandedData"
    >
      <template v-slot:body="{ items, expand, isExpanded, isMobile }">
        <tbody>
          <template v-if="!isMobile">
            <template v-for="item in items">
              <tr :key="item.index">
                <td>{{ item.fullMemberCode }}</td>
                <td>{{ !item.registeredMembers ? 0 : item.registeredMembers.length }}</td>
                <td>{{ !item.depositedMembers ? 0 : item.depositedMembers.length }}</td>
                <td>{{ !item.activeMembers ? 0 : item.activeMembers.length }}</td>
                <td>{{ item.winLoss | currency }}</td>
                <td>{{ item.turnover | currency }}</td>
                <td>
                  <span>
                    <v-btn icon @click="getProductDownline(item), expand(item, !isExpanded(item))">
                      <v-icon>{{ isExpanded(item) ? `keyboard_arrow_up` : `keyboard_arrow_down` }}</v-icon>
                    </v-btn>
                  </span>
                </td>
              </tr>
            </template>
            <tr v-if="affiliateAgentDetail.list.length > 0">
              <td colspan="4" class="font-weight-bold secondary--text">{{ $t(`label.summary`) }}</td>
              <td class="font-weight-bold secondary--text">{{ affiliateAgentDetail.pageSummary.winLoss | currency }}</td>
              <td class="font-weight-bold secondary--text">{{ affiliateAgentDetail.pageSummary.turnover | currency }}</td>
              <td></td>
            </tr>
            <tr v-if="items.length <= 0">
              <td :colspan="tableHeaders.length" class="text-center">{{ $t(`message.noDataAvaliable`) }}</td>
            </tr>
          </template>
          <template v-else>
            <template v-for="item in items">
              <tr :key="item.index">
                <td>
                  <v-row no-gutters class="py-2">
                    <v-col>{{ $t('label.memberCode') }}</v-col>
                    <v-col class="text-right">{{ item.memberCode }}</v-col>
                  </v-row>
                  <v-row no-gutters class="py-2">
                    <v-col>{{ $t('label.newRegisterMember') }}</v-col>
                    <v-col class="text-right">{{ !item.registeredMembers ? 0 : item.registeredMembers.length }}</v-col>
                  </v-row>
                  <v-row no-gutters class="py-2">
                    <v-col>{{ $t('label.depositedMember') }}</v-col>
                    <v-col class="text-right">{{ !item.depositedMembers ? 0 : item.depositedMembers.length }}</v-col>
                  </v-row>
                  <v-row no-gutters class="py-2">
                    <v-col>{{ $t('label.validMember') }}</v-col>
                    <v-col class="text-right">{{ !item.activeMembers ? 0 : item.activeMembers.length }}</v-col>
                  </v-row>
                  <v-row no-gutters class="py-2">
                    <v-col>{{ $t('label.memberWinLoss') }}</v-col>
                    <v-col class="text-right">{{ item.winLoss | currency }}</v-col>
                  </v-row>
                  <v-row no-gutters class="py-2">
                    <v-col>{{ $t('label.memberTurnover') }}</v-col>
                    <v-col class="text-right">{{ item.turnover | currency }}</v-col>
                  </v-row>
                  <v-row no-gutters class="py-2">
                    <v-col>
                      <app-button
                        customClass="buttonPrimary white--text mt-0 mb-2 body-2 pa-2"
                        :title="isExpanded(item) ? $t('label.close') : $t('label.moreDetail')"
                        :action="
                          () => {
                            getProductDownline(item), expand(item, !isExpanded(item))
                          }
                        "
                      ></app-button>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </template>
            <tr v-if="affiliateAgentDetail.list.length > 0">
              <td>
                <v-row no-gutters class="py-2">
                  <v-col class="font-weight-bold secondary--text text-center">{{ $t('label.summary') }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col class="font-weight-bold secondary--text">{{ $t('label.memberWinLoss') }}</v-col>
                  <v-col class="text-right font-weight-bold secondary--text">{{ affiliateAgentDetail.pageSummary.winLoss | currency }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col class="font-weight-bold secondary--text">{{ $t('label.memberTurnover') }}</v-col>
                  <v-col class="text-right font-weight-bold secondary--text">{{ affiliateAgentDetail.pageSummary.turnover | currency }}</v-col>
                </v-row>
              </td>
            </tr>

            <tr v-if="items.length <= 0">
              <td :colspan="tableHeaders.length" class="text-center">{{ $t(`message.noDataAvaliable`) }}</td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-data-table>

    <v-data-table v-if="expandedData.length > 0" mobile-breakpoint="800" class="transaction-table px-4" :headers="downLineTableHeaders" :items="expandedData[0].productType" hide-default-footer>
      <template v-slot:body="{ items, isMobile }">
        <tbody>
          <template v-if="!isMobile && items.length > 0">
            <tr v-for="item in items" :key="`${item.productType}_D`">
              <td>{{ $t(`label.${item.productType}`) }}</td>
              <td>{{ item.memberCount }}</td>
              <td>{{ item.winLoss | currency }}</td>
              <td>{{ item.turnover | currency }}</td>
            </tr>

            <tr>
              <td :colspan="2" class="font-weight-bold secondary--text">{{ $t(`label.summary`) }}</td>
              <td class="font-weight-bold secondary--text">{{ expandedData[0].productRecordSummary.winLoss | currency }}</td>
              <td class="font-weight-bold secondary--text">{{ expandedData[0].productRecordSummary.turnover | currency }}</td>
            </tr>
          </template>
          <template v-if="isMobile && items.length > 0">
            <tr v-for="item in items" :key="`${item.productType}_M`">
              <td>
                <v-row no-gutters class="py-2">
                  <v-col>{{ $t('label.productType') }}</v-col>
                  <v-col class="text-right">{{ $t(`label.${item.productType}`) }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col>{{ $t('label.memberCount') }}</v-col>
                  <v-col class="text-right">{{ item.memberCount }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col>{{ $t('label.memberWinLoss') }}</v-col>
                  <v-col class="text-right">{{ item.winLoss | currency }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col>{{ $t('label.memberTurnover') }}</v-col>
                  <v-col class="text-right">{{ item.turnover | currency }}</v-col>
                </v-row>
              </td>
            </tr>

            <tr>
              <td>
                <v-row no-gutters class="py-2">
                  <v-col class="font-weight-bold secondary--text text-center">{{ $t('label.summary') }}</v-col>
                </v-row>

                <v-row no-gutters class="py-2">
                  <v-col class="font-weight-bold secondary--text">{{ $t('label.memberWinLoss') }}</v-col>
                  <v-col class="text-right font-weight-bold secondary--text">{{ expandedData[0].productRecordSummary.winLoss | currency }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col class="font-weight-bold secondary--text">{{ $t('label.memberTurnover') }}</v-col>
                  <v-col class="text-right font-weight-bold secondary--text">{{ expandedData[0].productRecordSummary.turnover | currency }}</v-col>
                </v-row>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-data-table>

    <v-row class="px-4" justify="end">
      <v-col class="align body-2" cols="12">
        <label>{{ `${$t(`label.lastUpdatedDate`)}: ` }}</label>
        <label>{{ affiliateAgentDetail.lastUpdatedDate | longDate }}</label>
      </v-col>
    </v-row>
    <v-row no-gutters class="px-4" v-if="expandedData.length <= 0">
      <v-col cols="6" sm="4" lg="3">
        <div class="mx-2 my-1">
          <p class="body-2 ma-0 my-2 text-uppercase font-weight-bold secondary--text">{{ $t(`label.winLoss`) }}</p>
          <v-card tile class="px-2 py-1">{{ affiliateAgentDetail.recordSummary.winLoss | currency }}</v-card>
        </div>
      </v-col>
      <v-col cols="6" sm="4" lg="3">
        <div class="mx-2 my-1">
          <p class="body-2 ma-0 my-2 text-uppercase font-weight-bold secondary--text">{{ $t(`label.turnover`) }}</p>
          <v-card tile class="px-2 py-1">{{ affiliateAgentDetail.recordSummary.turnover | currency }}</v-card>
        </div>
      </v-col>
    </v-row>
    <v-row class="px-4" justify="end" v-if="expandedData.length <= 0">
      <v-col class="align body-2" cols="12">
        <v-pagination v-model="currentPage" :length="totalPage" @input="changePage" :total-visible="paginationPageLimit"></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { AFFILIATE_AGENT_REPORT, AFFILIATE_AGENT_PRODUCT_DOWNLINE_REPORT } from '@/store/affiliate.module'
import { locale, uiHelper } from '@/util'
import { SHARED } from '@/constants/constants'
export default {
  name: 'affiliateAgentReport',
  data: () => ({
    language: uiHelper.getLanguage(),
    minSelectableDate: new Date(new Date().setDate(new Date().getDate() - 90)).toISOString().substr(0, 10),
    maxSelectableDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    searchDate: [new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().substr(0, 10), new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)],
    searchDateMenu: false,
    expandedData: [],
    currentPage: 1,
    totalPage: 1,
    paginationPageLimit: 5,
    tableHeaders: [
      {
        text: locale.getMessage('label.memberCode'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        sortable: false
      },
      {
        text: locale.getMessage('label.newRegisterMember'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        value: 'registeredMembers',
        sortable: true
      },
      {
        text: locale.getMessage('label.depositedMember'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        value: 'depositedMembers',
        sortable: true
      },
      {
        text: locale.getMessage('label.validMember'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        value: 'activeMembers',
        sortable: true
      },
      {
        text: locale.getMessage('label.memberWinLoss'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        value: 'winLoss',
        sortable: true
      },
      {
        text: locale.getMessage('label.memberTurnover'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        value: 'turnover',
        sortable: true
      },
      {
        text: '',
        align: 'left',
        class: 'body-2 font-weight-bold',
        width: '30'
      }
    ],
    downLineTableHeaders: [
      {
        text: locale.getMessage('label.productType'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        value: 'productType',
        sortable: true
      },
      {
        text: locale.getMessage('label.memberCount'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        value: 'memberCount',
        sortable: true
      },
      {
        text: locale.getMessage('label.memberWinLoss'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        value: 'winLoss',
        sortable: true
      },
      {
        text: locale.getMessage('label.memberTurnover'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        value: 'turnover',
        sortable: true
      }
    ],
    searchCriteria: {
      mode: 3,
      pageNumber: SHARED.DEFAULT_PAGE_NUMBER,
      pageSize: SHARED.DEFAULT_PAGE_SIZE,
      startDate: '',
      endDate: ''
    }
  }),
  computed: {
    displaySearchDate() {
      return this.searchDate.join(` ${locale.getMessage('label.to')} `)
    },
    affiliateAgentDetail() {
      return this.$store.state.affiliate.agentDetail
    },
    affiliateAgentDetailPagination() {
      return this.$store.state.affiliate.agentDetail.pagination
    }
  },
  watch: {
    affiliateAgentDetailPagination() {
      this.currentPage = this.$store.state.affiliate.agentDetail.pagination.pageNumber
      this.totalPage = this.$store.state.affiliate.agentDetail.pagination.lastPage
    }
  },
  created() {
    this.getAgentReport()
  },
  methods: {
    getProductDownline(item) {
      if (item.productType.length <= 0) {
        let obj = {
          mode: 3,
          size: '',
          page: '',
          startDate: this.searchDate[0],
          endDate: this.searchDate[1],
          memberCode: item.fullMemberCode
        }

        this.$store.dispatch(`${AFFILIATE_AGENT_PRODUCT_DOWNLINE_REPORT}`, { obj })
      }
    },
    reorderDateRangeValue(x) {
      if (new Date(x[0]) >= new Date(x[1])) {
        let startDate = x[1]
        let endDate = x[0]
        this.searchDate[0] = startDate
        this.searchDate[1] = endDate
      }
    },
    getAgentReport() {
      this.expandedData = []
      let obj = {
        mode: this.searchCriteria.mode,
        size: this.searchCriteria.pageSize,
        page: this.searchCriteria.pageNumber,
        affiliateType: 2,
        startDate: this.searchDate[0],
        endDate: this.searchDate[1]
      }
      this.$store.dispatch(`${AFFILIATE_AGENT_REPORT}`, { obj })
    },
    changePage(targetPage) {
      this.searchCriteria.pageNumber = targetPage
      this.getAgentReport()
    },
    searchAgentDetailReport() {
      this.changePage(1)
    }
  }
}
</script>
<style lang="scss">
.transaction-page-search-date-field-box {
  width: 280px;
}
</style>
